/**
 * RequireJS
 *
 * RequireJS is a JavaScript file and module loader. It is optimized for in-browser use,
 * but it can be used in other JavaScript environments, like Rhino and Node. Using a modular script
 * loader like RequireJS will improve the speed and quality of your code.
 */

/* jshint ignore:start */
// =require ../../../bower_components/requirejs/require.js
/* jshint ignore:end */
